<template>
  <v-container class="p-4">
    <loading-flux :value="loading"></loading-flux>
    <section class="d-flex flex-column flex-sm-row justify-content-between">
      <h2 class="ml-1 my-3">
        Suplencia
      </h2>
      <v-breadcrumbs :items="breadcrumb"></v-breadcrumbs>
    </section>
    <v-row align-content="center" align-md="center" class="mt-1"> </v-row>
    <v-data-table
      class="elevation-1 px-5 py-3 mt-1"
      :headers="tableHeaders"
      :items="employeesData.employees"
      :items-per-page="20"
      :loading="loadingEmployees"
      :page.sync="filters.page"
      loading-text="Cargando empleados"
      item-key="id"
      hide-default-footer
      no-data-text="Sin trabajadores"
      @page-count="pageCount = $event"
    >
      <template v-slot:top>
        <v-toolbar flat class="w-50 pt-5">
          <v-text-field
            label="Busqueda"
            v-model="filters.filter"
            @input="($event) => getEmployeesData()"
            class="mb-5"
            clearable
            placeholder="Buscar trabajador"
            append-icon="mdi-magnify"
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex align-items-center justify-content-center">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="openModalUpdateOrCreateEmployee(item)"
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </template>
            <span>Activar / Desactivar Suplencia</span>
          </v-tooltip>
          <v-tooltip top v-if="item.substituting_for.length">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="openModalDeleteEmployeeSubstitute(item)"
                icon
                color="red darken-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  mdi-cancel
                </v-icon>
              </v-btn>
            </template>
            <span>Cancelar Suplencia</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:item.is_active="{ item }">
        <v-chip
          :color="item.substituting_for.length ? 'primary' : 'red darken-2'"
          class="text-white"
        >
          {{ item.substituting_for.length ? "Activo" : "Inactivo" }}</v-chip
        >
      </template>
      <template v-slot:item.substitute="{ item }">
        <span>
          {{
            item.substituting_for.length
              ? getSubstitute(item.substituting_for)
              : "Sin suplente"
          }}</span
        >
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        total-visible="8"
        v-model="filters.page"
        :length="employeesData.pages"
      ></v-pagination>
    </div>
    <update-or-create-employee
      :modalAction="dialogUpdateOrCreateEmployees"
      :closeModalEmployee="closeModalUpdateOrCreateEmployee"
      :currentEmployee="currentEmployee"
      :isSuperiorGroup="isSuperiorGroup"
      @employeeUpdated="getEmployeesData(filters.page)"
    ></update-or-create-employee>
  </v-container>
</template>

<script>
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import { mapActions, mapGetters } from "vuex";
import config from "@/config";
import UpdateOrCreateEmployee from "./UpdateOrCreateEmployee.vue";
import { authUsuarioComputed } from "@/state/helpers";
import { GROUPS_USER_MANAGMENT } from "@/constants/approval";

export default {
  components: { UpdateOrCreateEmployee },
  watch: {
    "filters.page": function(page) {
      this.getEmployeesData(page);
    },
  },
  mounted() {
    this.getEmployeesData();
  },
  data() {
    return {
      isSuperiorGroup: false,
      breadcrumb: [
        {
          text: "Proyectos",
          disabled: false,
          href: "/",
        },
        {
          text: "suplencia",
          disabled: true,
          href: "/mantenedores/suplencia",
        },
      ],
      dialogUpdateOrCreateEmployees: false,
      currentEmployee: null,
      loadingEmployees: false,
      filters: {
        page: 1,
        filter: "",
      },
      debounce: null,
      debounceTime: 500,
      pageCount: 0,
      tableHeaders: [
        {
          text: "Acciones",
          align: "center",
          sortable: false,
          value: "actions",
        },
        {
          text: "Nombre",
          align: "center",
          sortable: false,
          value: "full_name",
        },
        {
          text: "RUT",
          align: "center",
          sortable: false,
          value: "rut",
        },
        {
          text: "Cargo",
          align: "center",
          sortable: false,
          value: "position",
        },
        {
          text: "Suplencia",
          align: "center",
          sortable: false,
          value: "is_active",
        },
        {
          text: "Suplente",
          align: "center",
          sortable: false,
          value: "substitute",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      getEmployees: "maintainers/getEmployees",
      sendDeleteEmployeeSubstitute: "maintainers/deleteEmployeeSubstitute",
    }),
    async getEmployeesData(page = 1) {
      clearTimeout(this.debounce);
      let self = this;
      this.debounce = await setTimeout(async function() {
        self.loadingEmployees = true;
        await self.getEmployees({
          password: config.password_manteiners,
          user: self.user.email,
          page: page - 1,
          filter: self.filters.filter ?? undefined,
        });
        self.loadingEmployees = false;
      }, self.debounceTime);
    },
    openModalUpdateOrCreateEmployee(employee) {
      this.currentEmployee = employee;
      let groupUser = GROUPS_USER_MANAGMENT[employee.category.name];
      if (groupUser >= 4) {
        this.isSuperiorGroup = true;
      }
      this.dialogUpdateOrCreateEmployees = true;
    },
    closeModalUpdateOrCreateEmployee($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.dialogUpdateOrCreateEmployees = false;
        this.currentEmployee = null;
        this.isSuperiorGroup = false;
      }
    },
    getSubstitute(substituting_employees) {
      let substitute =
        substituting_employees[substituting_employees.length - 1].substitute;
      return `${substitute.full_name} (${substitute.rut})`;
    },
    openModalDeleteEmployeeSubstitute({ id }) {
      let self = this;
      this.$swal
        .fire({
          icon: "warning",
          title: `Deseas cancelar la suplencia que posees activa ?`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "red",
          confirmButtonText: "Cancelar",
          cancelButtonText: `Cerrar`,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const resp = await this.sendDeleteEmployeeSubstitute({
              employee_id: id,
            });
            if (resp.status === 200) {
              self.$swal({
                icon: "success",
                title: "Excelente",
                text: "Suplencia eliminada correctamente",
                position: "top-end",
                showConfirmButton: false,
                toast: true,
                timer: 3000,
                timerProgressBar: true,
              });
              this.getEmployeesData();
            }
          }
        });
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      loading: "maintainers/loading",
      employeesData: "maintainers/employees",
    }),
  },
};
</script>

<style></style>
