export const GROUPS_USER_MANAGMENT = {
    "Gerente General": 1,
    "Gerente Finanzas": 2,
    "Gerentes": 3,
    "Subgerentes": 4,
    "Jefes / Lideres": 5,
    "Supervisores / AO / Site": 6,
    "Otros": 7
}

export const STATUS_APPROVAL_LIST = [
    {
        id: 'pending',
        name: 'Pendiente'
    },
    {
        id: 'approved',
        name: 'Aprobada'
    },
    {
        id: 'rejected',
        name: 'Rechazada'
    },
    {
        id: 'cancelled',
        name: 'Anulada'
    }
]