var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"p-4"},[_c('loading-flux',{attrs:{"value":_vm.loading}}),_c('section',{staticClass:"d-flex flex-column flex-sm-row justify-content-between"},[_c('h2',{staticClass:"ml-1 my-3"},[_vm._v(" Suplencia ")]),_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumb}})],1),_c('v-row',{staticClass:"mt-1",attrs:{"align-content":"center","align-md":"center"}}),_c('v-data-table',{staticClass:"elevation-1 px-5 py-3 mt-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.employeesData.employees,"items-per-page":20,"loading":_vm.loadingEmployees,"page":_vm.filters.page,"loading-text":"Cargando empleados","item-key":"id","hide-default-footer":"","no-data-text":"Sin trabajadores"},on:{"update:page":function($event){return _vm.$set(_vm.filters, "page", $event)},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"w-50 pt-5",attrs:{"flat":""}},[_c('v-text-field',{staticClass:"mb-5",attrs:{"label":"Busqueda","clearable":"","placeholder":"Buscar trabajador","append-icon":"mdi-magnify"},on:{"input":function ($event) { return _vm.getEmployeesData(); }},model:{value:(_vm.filters.filter),callback:function ($$v) {_vm.$set(_vm.filters, "filter", $$v)},expression:"filters.filter"}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.openModalUpdateOrCreateEmployee(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Activar / Desactivar Suplencia")])]),(item.substituting_for.length)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red darken-2"},on:{"click":function($event){return _vm.openModalDeleteEmployeeSubstitute(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-cancel ")])],1)]}}],null,true)},[_c('span',[_vm._v("Cancelar Suplencia")])]):_vm._e()],1)]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"text-white",attrs:{"color":item.substituting_for.length ? 'primary' : 'red darken-2'}},[_vm._v(" "+_vm._s(item.substituting_for.length ? "Activo" : "Inactivo"))])]}},{key:"item.substitute",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.substituting_for.length ? _vm.getSubstitute(item.substituting_for) : "Sin suplente"))])]}}])}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"total-visible":"8","length":_vm.employeesData.pages},model:{value:(_vm.filters.page),callback:function ($$v) {_vm.$set(_vm.filters, "page", $$v)},expression:"filters.page"}})],1),_c('update-or-create-employee',{attrs:{"modalAction":_vm.dialogUpdateOrCreateEmployees,"closeModalEmployee":_vm.closeModalUpdateOrCreateEmployee,"currentEmployee":_vm.currentEmployee,"isSuperiorGroup":_vm.isSuperiorGroup},on:{"employeeUpdated":function($event){return _vm.getEmployeesData(_vm.filters.page)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }