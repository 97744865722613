<template>
  <section>
    <v-dialog
      v-model="modalAction"
      max-width="1000px"
      scrollable
      @keydown="executeCloseModalEmployee"
      @click:outside="executeCloseModalEmployee"
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title>
            Activar/Desactivar Suplencia Trabajador
            {{
              currentEmployee
                ? `${currentEmployee.full_name} (${currentEmployee.email})`
                : ""
            }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-form class="my-5 px-5">
            <v-row align-content="start" justify="start">
              <v-col cols="12" md="6" sm="12">
                <div
                  class="d-flex align-items-center justify-content-start mt-2"
                >
                  <span for="" class="mb-0 mt-1"> Activar Suplencia </span>
                  <v-checkbox
                    hide-details
                    dense
                    v-model="$v.form.is_active.$model"
                    class="mx-3 mt-0"
                  >
                    <template v-slot:label>
                      <h5 class="mt-4">Activo</h5>
                    </template>
                  </v-checkbox>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="6"
                sm="12"
                v-if="
                  (!isSuperiorGroup || user.nombre_rol === 'SUPER USUARIO') &&
                  form.is_active
                "
              >
                <v-autocomplete
                  :items="wrappedSubstituteEmployees"
                  v-model="$v.form.substitute.$model"
                  item-text="label"
                  item-value="id"
                  @update:search-input="getSubstituteEmployeesData($event)"
                  :loading="loadingSubstitute"
                  searchable
                  label="Suplente"
                  placeholder="Selecciona un suplente"
                  hide-details
                ></v-autocomplete>
              </v-col>
              <!-- <v-col
                cols="12"
                md="6"
                sm="12"
                class="pt-2"
                v-if="form.is_active"
              >
                <label for="date_start" class="mt-2 mb-0">
                  Desde
                </label>
                <v-menu
                  ref="menu"
                  v-model="datestart_menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_start_formatted"
                      prepend-icon="mdi-calendar"
                      readonly
                      placeholder="Seleccionar fecha de inicio de suplencia"
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                      :error="$v.form.date_start.$invalid && submitUpload"
                      :error-messages="
                        $v.form.date_start.$invalid && submitUpload
                          ? 'Este campo es requerido'
                          : null
                      "
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.date_start"
                    scrollable
                    locale="es"
                    :max="maxDateStart"
                    @input="datestart_menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                cols="12"
                md="6"
                sm="12"
                class="pt-2"
                v-if="form.is_active"
              >
                <label for="date_end" class="mt-2 mb-0">
                  Hasta
                </label>
                <v-menu
                  ref="menu"
                  v-model="dateend_menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_end_formatted"
                      prepend-icon="mdi-calendar"
                      :error="$v.form.date_end.$invalid && submitUpload"
                      :error-messages="
                        $v.form.date_end.$invalid && submitUpload
                          ? 'Este campo es requerido'
                          : null
                      "
                      readonly
                      placeholder="Seleccionar fecha de cierre de suplencia"
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.date_end"
                    scrollable
                    locale="es"
                    :min="minDateEnd"
                    @input="dateend_menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col> -->
              <v-col cols="12" sm="6" class="pt-2" v-if="form.is_active">
                <v-date-picker
                  v-model="form.dates"
                  range
                  locale="es"
                  class="w-100 pr-5 mt-3"
                  :selected-items-text="getTextDatesSelected"
                  :events="highlightNextDay"
                  event-color="green"
                  :min="minDateStart"
                ></v-date-picker>
              </v-col>
              <v-col cols="12" sm="6" v-if="form.is_active">
                <v-text-field
                  v-model="dateRangeText"
                  label="Selecciona el rango de fechas de la suplencia"
                  prepend-icon="mdi-calendar"
                  readonly
                ></v-text-field>
                <h5
                  class="text-primary mt-2"
                  v-if="form.is_active && form.dates.length < 2"
                >
                  <b>ATENCIÓN:</b> Selecciona la fecha de inicio y la fecha de
                  cierre de la suplencia
                </h5>
                <h5 v-if="form.dates.length === 2">
                  <v-icon small class="mb-1" color="green" size="20">
                    mdi-circle
                  </v-icon>
                  <small class="mx-2">
                    El día de retorno será el
                    {{
                      moment(form.dates[1]).add(1, "days").format("DD/MM/YYYY")
                    }}
                  </small>
                </h5>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary darken-1"
            class="text-white"
            @click="updateOrCreateEmployeeSubstitute"
          >
            <small>
              {{ currentEmployee ? "Actualizar" : "Agregar" }} empleado
            </small>
          </v-btn>
          <v-btn
            color="red darken-1"
            outlined
            @click="executeCloseModalEmployee"
          >
            <small> Cerrar </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { formatDateToHuman } from "@/helpers/common";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import config from "@/config";
import { mapActions, mapGetters } from "vuex";
import swal from "sweetalert2";
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import { authComputed } from "@/state/helpers";
import { authUsuarioComputed } from "@/state/helpers";

export default {
  props: {
    modalAction: {
      type: Boolean,
      required: true,
    },
    closeModalEmployee: {
      type: Function,
      default: () => {},
    },
    currentEmployee: {
      default: null,
    },
    isSuperiorGroup: {
      default: true,
    },
  },
  validations: {
    form: {
      is_active: {},
      substitute: {},
      // date_start: { required },
      // date_end: { required },
      dates: { required },
    },
  },
  data() {
    return {
      moment,
      datestart_menu: true,
      dateend_menu: false,
      form: {
        is_active: true,
        substitute: null,
        date_start: null,
        date_end: null,
        dates: [],
      },
      submitUpload: false,
      debounce: null,
      debounceTime: 500,
      loadingSubstitute: false,
    };
  },
  mounted() {
    this.getSubstituteEmployeesData();
  },
  methods: {
    ...mapActions({
      getSubstituteEmployees: "maintainers/getSubstituteEmployees",
      sendUpdateEmployeeSubstitute: "maintainers/updateEmployeeSubstitute",
      sendUpdateEmployeeSubstituteAutomatic:
        "maintainers/updateEmployeeSubstituteAutomatic",
    }),
    async getSubstituteEmployeesData(search = "") {
      if (search === null || !search.includes("(")) {
        clearTimeout(this.debounce);
        let self = this;
        this.debounce = await setTimeout(async function () {
          self.loadingSubstitute = true;
          await self.getSubstituteEmployees({
            password: config.password_manteiners,
            page: 0,
            filter: search ?? undefined,
          });
          self.loadingSubstitute = false;
        }, self.debounceTime);
      }
    },
    executeCloseModalEmployee($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.resetForm();
        this.closeModalEmployee($event);
      }
    },
    resetForm() {
      this.form.substitute = null;
      this.form.is_active = true;
      this.form.date_start = null;
      this.form.date_end = null;
      this.form.dates = [];
      this.submitUpload = false;
    },
    async updateOrCreateEmployeeSubstitute() {
      if (this.form.dates.length < 2) {
        swal.fire({
          icon: "error",
          title: "Error",
          text: "Selecciona la fecha de inicio y la fecha de cierre de la suplencia",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
        return;
      }
      this.submitUpload = true;
      if (!this.$v.form.$invalid) {
        let payload = {
          employee_id: this.currentEmployee.id,
          password: config.password_manteiners,
          is_active: this.form.is_active,
          start_date: `${this.form.dates[0]} 00:00:00`,
          end_date: `${moment(this.form.dates[1])
            .add(1, "days")
            .format("YYYY-MM-DD")} 00:00:00`,
          substitute_id:
            this.form.substitute &&
            (!this.isSuperiorGroup || this.user.nombre_rol === "SUPER USUARIO")
              ? this.form.substitute
              : undefined,
        };
        let response = null;
        if (this.isSuperiorGroup && this.user.nombre_rol !== "SUPER USUARIO") {
          response = await this.sendUpdateEmployeeSubstituteAutomatic(payload);
        } else {
          response = await this.sendUpdateEmployeeSubstitute(payload);
        }
        if (response.status == 422 || response.status == 400) {
          swal.fire({
            icon: "error",
            title: "Error",
            text: `${
              response.data?.detail
                ? response.data?.detail
                : "Error de validación"
            }`,
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 4000,
            timerProgressBar: true,
          });
          return;
        }
        if (response.status == 200 || response.status == 201) {
          swal.fire({
            icon: "success",
            title: "Excelente",
            text: `Suplencia de empleado actualizada con éxito`,
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 4000,
            timerProgressBar: true,
          });
          this.resetForm();
          this.$emit("employeeUpdated");
          this.closeModalEmployee();
        }
        if (response.status == 500 || !response.status) {
          swal.fire({
            icon: "error",
            title: "Error",
            text: "Ha ocurrido un error en el servidor, intentalo nuevamente",
            position: "top-end",
            showConfirmButton: false,
            toast: true,
            timer: 4000,
            timerProgressBar: true,
          });
        }
      }
    },
    setCurrentEmployee(employee) {
      let substitute = employee.substituting_for.length
        ? employee.substituting_for[employee.substituting_for.length - 1]
        : null;
      this.form.is_active = substitute ? true : false;
      this.form.substitute = substitute ? substitute.substitute.id : null;
      this.form.date_start = substitute
        ? moment(substitute.start_date).format("YYYY-MM-DD")
        : null;
      this.form.date_end = substitute
        ? moment(substitute.end_date).format("YYYY-MM-DD")
        : null;
      this.form.dates = substitute
        ? [
            moment(substitute.start_date).format("YYYY-MM-DD"),
            moment(substitute.end_date)
              .subtract(1, "days")
              .format("YYYY-MM-DD"),
          ]
        : [];
    },
    highlightNextDay(date) {
      if (this.form.dates.length === 2) {
        const endDate = new Date(this.form.dates[1]);
        const nextDay = new Date(endDate);
        nextDay.setDate(endDate.getDate() + 1);
        return date === nextDay.toISOString().substr(0, 10);
      }
      return false;
    },
  },
  computed: {
    ...authComputed,
    ...authUsuarioComputed,
    ...mapGetters({
      substituteEmployees: "maintainers/substituteEmployees",
      positions: "maintainers/positions",
      submanagments: "maintainers/submanagments",
    }),
    wrappedSubstituteEmployees() {
      return this.substituteEmployees.employees
        .filter((item) => item.id !== this.currentEmployee?.id)
        .map((item) => {
          return {
            label: `${item.full_name} (${item.email})`,
            ...item,
          };
        });
    },
    date_start_formatted() {
      if (this.form.date_start) {
        return formatDateToHuman(this.form.date_start);
      }
      return null;
    },
    date_end_formatted() {
      if (this.form.date_end) {
        return formatDateToHuman(this.form.date_end);
      }
      return null;
    },
    minDateEnd() {
      if (this.form.date_start) {
        return this.form.date_start;
      }
      return null;
    },
    maxDateStart() {
      if (this.form.date_end) {
        return this.form.date_end;
      }
      return null;
    },
    dateRangeText() {
      if (this.form.dates.length > 1) {
        return (
          moment(this.form.dates[0]).format("DD/MM/YYYY") +
          " ~ " +
          moment(this.form.dates[1]).format("DD/MM/YYYY")
        );
      }
      return this.form.dates.join(" ~ ");
    },
    getTextDatesSelected() {
      if (this.form.dates.length > 1) {
        let start = moment(this.form.dates[0]);
        let end = moment(this.form.dates[1]);
        return `Dias de suplencia: ${moment(end).diff(start, "days") + 1}`;
      }
      return null;
    },
    minDateStart() {
      if (this.form.dates.length == 1) {
        return this.form.dates[0];
      }
      return null;
    },
  },
  watch: {
    currentEmployee: {
      handler(newEmployee) {
        if (newEmployee) {
          this.datestart_menu = false;
          this.dateend_menu = false;
          this.setCurrentEmployee(newEmployee);
        } else {
          this.resetForm();
        }
      },
    },
  },
};
</script>

<style></style>
