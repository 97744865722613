import moment from "moment"

export const preventCloseIfNotKeyEventEsc = ($event) => {
  if ($event && ($event.keyCode  || !$event.composed)) {
    if ($event.keyCode !== 27) {
      return null
    }
    if (!$event.composed) {
      return null
    }
  }
  return true
}

export const getExtensionFileFromUrl = (url) => {
  return url
    .split(/[#?]/)[0]
    .split('.')
    .pop()
    .trim()
}

export const formatDateToHuman = (date) => {
  if (!date) return null

  const [year, month, day] = date.split('-')
  return `${day}/${month}/${year}`
}

export const isUrlValid = (urlString) => {
  try {
    const regex1Url = /^(https?:\/\/)?([\w.-]+\.[\w.-]+)$/;
    const regex2Url = /^(?:(?:https?|ftp):\/\/)(?:\w+(?::\w+)?@)?(?:[a-z0-9.-]+\.[a-z]{2,})(:[0-9]+)?(?:\/[\w.-]+)*(?:\/?|\/\?[^#]*)#?(?:fragment)?$/i;
    return regex1Url.test(urlString) || regex2Url.test(urlString);
  } catch (error) {
      return false;
  }
}

export const calculateDiferenceTwoDates = (date_start, date_end) => {
  const milisecondsPerDay = 60 * 1000

  // Parsea las fechas en formato ISO
  const dateStartFormatted = new Date(date_start);
  const dateEndFormatted = new Date(date_end);

  // Calcula la diferencia en milisegundos
  const diferenceMiliseconds = Math.abs(dateEndFormatted - dateStartFormatted);

  // Calcula el número de días y horas
  const days = Math.floor(diferenceMiliseconds / (24 * 60 * 60 * 1000));
  const hours = Math.floor((diferenceMiliseconds % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
  let minutes
  if(hours <= 0){
    minutes = Math.floor((diferenceMiliseconds % (60 * 60 * 1000)) / milisecondsPerDay);
  }

  // Crea el string de resultado
  const resultDays = `${days} Día${days !== 1 ? 's' : ''}`;
  const resultTime = hours >= 1 ? ` ${hours} Hora${hours !== 1 ? 's' : ''}` : ` ${minutes} Minuto${minutes !== 1 ? 's' : ''}` 
  
  return resultDays + resultTime;
}
// Function to return and rename a file
export const renameAndReturnNewFile = (file, newName) => {
  return new File([file], newName, { type: file.type });
}

//Formatear fecha a formato dd/mm/yyyy
export const formatDateToFormatCommon = (date) => {
  if (!date) return null
  let day = ("0" + date.getDate()).slice(-2);
  let month = ("0" + (date.getMonth() + 1)).slice(-2);
  let year = date.getFullYear();
  let dateFormatted = `${day}/${month}/${year}`
  return dateFormatted
}

export const formatDateMomentToHuman = (date) => { 
  return moment(date).format('DD/MM/YYYY');
}
